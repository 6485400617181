<template>
  <Teleport to="body">
    <div
      aria-live="assertive"
      class="z-40 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:items-start"
    >
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="toast && toast.open"
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <FontAwesomeIcon
                    :icon="['fad', toast.icon || 'check']"
                    :style="{
                      '--fa-primary-color': primaryColor,
                      '--fa-secondary-color': secondaryColor,
                    }"
                    size="2x"
                  />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">
                    {{ toast.title }}
                  </p>
                  <p class="mt-1 text-sm text-gray-500">
                    <slot></slot>
                  </p>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                  <div
                    @click="emit('close')"
                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 cursor-pointer"
                  >
                    <span class="sr-only">Close</span>
                    <FontAwesomeIcon
                      :icon="['fad', 'xmark']"
                      :style="{ '--fa-secondary-color': colors.red[400] }"
                      swap-opacity
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import colors from "tailwindcss/colors";
import { computed, watch } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { Toast } from "~/models/toast";

const emit = defineEmits(["close"]);

const props = withDefaults(
  defineProps<{
    toast: Toast;
    closeAutomatically?: boolean;
  }>(),
  {
    closeAutomatically: true,
  },
);

const primaryColor = computed(() => {
  switch (props.toast.type) {
    case "error":
      return colors.red["400"];
    default:
      return colors.green["400"];
  }
});

const secondaryColor = computed(() => {
  switch (props.toast.type) {
    case "error":
      return colors.red["400"];
    default:
      return colors.green["400"];
  }
});

watch(props, (val) => {
  if (props.closeAutomatically) {
    setTimeout(() => {
      emit("close");
    }, 3000);
  }
});
</script>
